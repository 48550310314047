
import { useEffect, useMemo, Suspense, lazy } from 'react';
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
// import ScrollTo from './components/Ui/ScrollToTop';
import SupmitDone from './pages/SupmitDone';
import Layout from './pages/Layout';
import Landing from './pages/Landing';
import { Box, CircularProgress } from '@mui/material';
import { EasyAccess } from './pages/EasyAccess';
import WebDevelopment from './pages/WebDevelopment';

const Home = lazy(() =>  import ('./pages/Home'));
const Services = lazy(() =>  import ('./pages/Services'));
const Service = lazy(() =>  import ('./pages/Service'));
const AboutUs = lazy(() =>  import ('./pages/AboutUs'));
const Contact = lazy(() =>  import ('./pages/Contact'));
const Booking = lazy(() =>  import ('./pages/Booking'));
const StartNow = lazy(() =>  import ('./pages/StartNow'));
const PrivacyPolicy = lazy(() =>  import ('./pages/PrivacyPolicy'));
const InstitutionPolicy = lazy(() =>  import ('./pages/InstitutionPolicy'));
const SiteMap = lazy(() =>  import ('./pages/SiteMap'));
const Blog = lazy(() =>  import ('./pages/Blog'));

const languages = [
  {
    code: 'ar',
    name: 'Ar',
    country_coode: 'sa',
    dir: 'rtl'
  },
  {
    code: 'en',
    name: 'En',
    country_coode: 'gb',
    dir: 'ltr'
  }
]


function App() {

  const currentLanguageCode = Cookies.get('i18next') || 'ar';
  const currentLanguage = useMemo(() => languages.find(l => l.code === currentLanguageCode), [currentLanguageCode])
  const { t } = useTranslation();
  useEffect(() => {

    document.dir = currentLanguage.dir || 'rtl';
    document.getElementsByTagName('html')[0].setAttribute('lang', currentLanguage.code || 'ar');

  }, [currentLanguage, t,]);
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  return (
    <div className="App">
      <CssBaseline />
      <ScrollToTop />
      
      <Suspense fallback={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100dvh' }}>
        <CircularProgress sx={{color:'#000'}}/>
      </Box>}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="/home" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/service/:id" element={<Service />} />
            {/* <Route path="/resources" element={<OurWork />} />
        <Route path="/resource/:id" element={<Work />} /> */}
            <Route path="/about" element={<AboutUs />} />
            <Route path="/blog/:id" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/start-now" element={<StartNow />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/institution" element={<InstitutionPolicy />} />
            <Route path="/site-map" element={<SiteMap />} />
            {/* SuccessPage */}
            <Route path="/done" element={<SupmitDone />} />
          </Route>
          <Route path="/marketing" element={<Landing />} />
          <Route path="/easy-access" element={<EasyAccess />} />
          <Route path="/web-development" element={<WebDevelopment />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
