import React from 'react'
import Nav from '../components/EasyAccess/Nav'
import ParticleBackground from '../components/glopal/ParticleBackground'
import MyLink from '../components/EasyAccess/MyLink'

export const EasyAccess = () => {
    return (
        <div>
            <Nav />
            <div className="gradientFull">
                <ParticleBackground />
                <MyLink />
            </div>
        </div>
    )
}
