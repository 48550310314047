import { Box, CardMedia, Typography } from '@mui/material';
import React from 'react';
import content from './../../assets/easyaccess/CONTENT.png';
import Behance from './../../assets/easyaccess/Behance.svg';
import dev from './../../assets/easyaccess/Circle-icons-dev.svg';
import UIUX from './../../assets/easyaccess/UIUX.png';
import Videos from './../../assets/easyaccess/Layer 1.png';
import Media from './../../assets/easyaccess/pngwing.com (2).png';
import { ArrowBack } from '@mui/icons-material'; 
import { useNavigate } from 'react-router-dom';
// Animation and Hover Styles
const animatedBoxStyles = {
    display: 'flex',
    justifyContent: 'center',
    gap: 1,
    alignItems: 'center',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',  // Add smooth transitions
    '&:hover': {
        transform: 'scale(1.05)', // Scale up the box on hover
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)', // Add shadow on hover
        cursor: 'pointer',
    },
};

const MyLink = () => {
    const handleOpenLink = (url) => {
        window.open(url, '_blank');  // Open the link in a new tab
    };
    const handleBackToHome = () => {
        window.location.href = '/';  // توجيه المستخدم إلى الصفحة الرئيسية
    };
    const navigate = useNavigate()
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: { md: 'center', xs: 'flex-end' }, height: '90%', mx: 2, position: 'relative' }} >
                <Typography variant={'h5'} color={'primary.main'} 
                    sx={{
                        cursor: 'pointer',
                        width: '100%',
                        textAlign:'center'
}}>
                    تم انشاء هذه الصفحة <br/>
                    <Typography sx={{ fontSize:'1.1rem'}} color={'#ffc65c'} >لتسهيل عملية تصفح جميع الخدمات من خلال الروابط التالية !</Typography> 
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', position: 'relative', gap: 2, width: {md:'fit-content', xs:'100%'}, }}>
                    {/* Content Box */}
                    <Box
                        color={'primary.main'}
                        sx={animatedBoxStyles}
                        onClick={() => handleOpenLink('https://example.com/content')}
                    >
                        <Typography >Content /Examples</Typography>
                        <CardMedia
                            component={'img'}
                            src={content}
                            sx={{ width: '45px' }}
                        />
                    </Box>
                    {/* Behance Box */}
                    <Box
                        color={'primary.main'}
                        sx={animatedBoxStyles}
                        onClick={() => handleOpenLink('https://www.behance.net/revampagency')}
                    >
                        <Typography >Behance</Typography>
                        <CardMedia
                            component={'img'}
                            src={Behance}
                            sx={{ width: '45px' }}
                        />
                    </Box>
                    {/* Dev Box */}
                    <Box
                        color={'primary.main'}
                        sx={animatedBoxStyles}
                        onClick={() => navigate('/web-development')}
                    >
                        <Typography >Web Development List</Typography>
                        <CardMedia
                            component={'img'}
                            src={dev}
                            sx={{ width: '45px' }}
                        />
                    </Box>
                    {/* UIUX Box */}
                    <Box
                        color={'primary.main'}
                        sx={animatedBoxStyles}
                        onClick={() => handleOpenLink('https://drive.google.com/file/d/1lZAdW_O7x191EYzwi3a9ShXMGodVtlJ1/view?usp=sharing')}
                    >
                        <Typography >Ui/Ux Designs</Typography>
                        <CardMedia
                            component={'img'}
                            src={UIUX}
                            sx={{ width: '45px' }}
                        />
                    </Box>
                    {/* Videos*/}
                    <Box
                        color={'primary.main'}
                        sx={animatedBoxStyles}
                        onClick={() => handleOpenLink('https://drive.google.com/drive/folders/1GVAGT_nn2dWZty1mPtHccKpk-f2tbZRz?usp=sharing')}
                    >
                        <Typography >Videos & Reels</Typography>
                        <CardMedia
                            component={'img'}
                            src={Videos}
                            sx={{ width: '45px' }}
                        />
                    </Box>
                    {/* Media*/}
                    <Box
                        color={'primary.main'}
                        sx={animatedBoxStyles}
                        onClick={() => handleOpenLink('https://example.com/content')}
                    >
                        <Typography >Media Buying</Typography>
                        <CardMedia
                            component={'img'}
                            src={Media}
                            sx={{ width: '45px' }}
                        />
                    </Box>
                    {/* <Box
                        color={'primary.main'}
                        sx={animatedBoxStyles}
                        onClick={() => handleOpenLink('https://wa.link/u0w17n')}
                    >
                        <Typography >Chat on Whatsapp</Typography>
                        <Avatar sx={{ width: "45px", height: "45px", backgroundColor: "#25D366" }} >
                            <WhatsAppIcon sx={{ fontSize: '2rem' }} />
                        </Avatar>
                    </Box>
                    <Box
                        color={'primary.main'}
                        sx={animatedBoxStyles}
                        onClick={() => handleOpenLink('tel:+201000814474')}
                    >
                        <Typography >! Call us Now </Typography>
                        <Avatar sx={{ width: "45px", height: "45px", backgroundColor: "#2548d3" }} >
                            <CallIcon sx={{ fontSize: '2rem' }} />
                        </Avatar>
                    </Box> */}
                </Box>
                {/* Back to Home Button at Bottom Center */}
                <Box
                    color={'primary.main'}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                        alignItems: 'center',
                        cursor: 'pointer',
                        width: '100%',

                    }}
                    onClick={handleBackToHome}
                >
                    <Typography >العودة للرئيسية </Typography>
                    <Box sx={{ backgroundColor: '#ffc65c', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '35px', width: '35px' }} >
                        <ArrowBack />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default MyLink;
