import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { CardMedia } from "@mui/material";
import logo from "./../../assets/logo.png"

function Nav() {


    return (
        <>
            <AppBar position="static" elevation={0}
                style={{
                    boxShadow: "rgba(255, 255, 255, 0.25) 0px 0px 12px inset",
                    backgroundColor: '#141414',
                    padding: "5px",
                    position: 'sticky',
                    top: 0,
                    zIndex: 100,
                    height: '80px',
                    justifyContent: 'center'
                }} >
                <Container maxWidth={'100%'} >
                    <Toolbar disableGutters>
                        <Box sx={{ width: '100%', display: { md: 'flex', xs: 'flex' }, justifyContent: 'center', alignItems: 'center' }} >
                            <Link
                                to="/"
                                style={{
                                    textDecoration: "none",
                                    mt: '10px'
                                }}>
                                <CardMedia src={logo} component={'img'} sx={{
                                    width: { md: '100px', xs: 'auto' },
                                    height: { md: '65px', xs: '40px' }, objectFit: 'contain'
                                }} alt="logo" />
                            </Link>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
}
export default Nav;
