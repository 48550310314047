import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ArrowBack } from '@mui/icons-material';

// Animation and Hover Styles
const animatedBoxStyles = {
    display: 'flex',
    justifyContent: 'center',
    gap: 1,
    alignItems: 'center',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)',
        cursor: 'pointer',
    },
};

// Link Styles for dark background
// const linkStyles = {
//     color: '#FFFFFF', // White text
//     textDecoration: 'none', // Remove underline
//     '&:hover': {
//         color: '#FFD700', // Gold color on hover
//         textDecoration: 'underline',
//     },
// };

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const MyLink = () => {
    const handleOpenLink = (url) => {
        window.open(url, '_blank'); // Open the link in a new tab
    };
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleBackToHome = () => {
        window.location.href = '/';  // توجيه المستخدم إلى الصفحة الرئيسية
    };
    return (
        <Box sx={{ width: 'fit-content', backgroundColor: '#000000', color: '#FFFFFF', display: 'flex', justifyContent: 'space-evenly', height: '100%', flexDirection: 'column', alignItems: 'flex-end', m: 'auto' }}> {/* Black background */}
            <Typography variant={'h5'} color={'primary.main'}
                sx={{
                    cursor: 'pointer',
                    width: '100%',
                    textAlign: 'center'
                }}>
                المشاريع البرمجية
                <br />
                <Typography sx={{ fontSize: '1.1rem' }} color={'#ffc65c'}>
                    استعرضوا الروابط التالية للوصول المباشر إلى بعض المشاريع البرمجية بمختلف الأنواع
                </Typography>
            </Typography>
            <Box sx={{m:'0 auto'}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab icon={<img width="48" height="48" src="https://img.icons8.com/color/48/react-native.png" alt="react-native" />} label="البرمجة الخاصة" {...a11yProps(0)} sx={{ color: '#FFFFFF' }} /> {/* White text */}
                        <Tab icon={<img width="48" height="48" src="https://img.icons8.com/color/48/wordpress.png" alt="wordpress" />} label="وورد بريس" {...a11yProps(1)} sx={{ color: '#FFFFFF' }} />
                        <Tab icon={<img width="48" height="48" src="https://img.icons8.com/color/48/shopify.png" alt="shopify" />} label="متاجر شوبيفاي" {...a11yProps(2)} sx={{ color: '#FFFFFF' }} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    {/* Five links for the first tab */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-end', position: 'relative' }}>
                        <Link sx={animatedBoxStyles} onClick={() => handleOpenLink('https://example.com/1')}>Link 1</Link>
                        <Link sx={animatedBoxStyles} onClick={() => handleOpenLink('https://example.com/2')}>Link 2</Link>
                        <Link sx={animatedBoxStyles} onClick={() => handleOpenLink('https://example.com/3')}>Link 3</Link>
                        <Link sx={animatedBoxStyles} onClick={() => handleOpenLink('https://example.com/4')}>Link 4</Link>
                        <Link sx={animatedBoxStyles} onClick={() => handleOpenLink('https://example.com/5')}>Link 5</Link>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    {/* Five links for the second tab */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-end', position: 'relative' }}>
                        <Link sx={animatedBoxStyles} onClick={() => handleOpenLink('https://example.com/a')}>Link A</Link>
                        <Link sx={animatedBoxStyles} onClick={() => handleOpenLink('https://example.com/b')}>Link B</Link>
                        <Link sx={animatedBoxStyles} onClick={() => handleOpenLink('https://example.com/c')}>Link C</Link>
                        <Link sx={animatedBoxStyles} onClick={() => handleOpenLink('https://example.com/d')}>Link D</Link>
                        <Link sx={animatedBoxStyles} onClick={() => handleOpenLink('https://example.com/e')}>Link E</Link>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    {/* Five links for the third tab */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-end', position: 'relative' }}>
                        <Link sx={animatedBoxStyles} onClick={() => handleOpenLink('https://example.com/x')}>Link X</Link>
                        <Link sx={animatedBoxStyles} onClick={() => handleOpenLink('https://example.com/y')}>Link Y</Link>
                        <Link sx={animatedBoxStyles} onClick={() => handleOpenLink('https://example.com/z')}>Link Z</Link>
                        <Link sx={animatedBoxStyles} onClick={() => handleOpenLink('https://example.com/alpha')}>Link Alpha</Link>
                        <Link sx={animatedBoxStyles} onClick={() => handleOpenLink('https://example.com/beta')}>Link Beta</Link>
                    </Box>
                </CustomTabPanel>
            </Box>
            {/* Back to Home Button at Bottom Center */}
            <Box
                color={'primary.main'}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 2,
                    alignItems: 'center',
                    cursor: 'pointer',
                    width: '100%',

                }}
                onClick={handleBackToHome}
            >
                <Typography >العودة للرئيسية </Typography>
                <Box sx={{ backgroundColor: '#ffc65c', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '35px', width: '35px' }} >
                    <ArrowBack />
                </Box>
            </Box>
        </Box>
    );
};

export default MyLink;
